// i18next-extract-mark-ns-start products-ignite

import { IGNITE_NAV, LINKS } from '@utils/constants';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import Button from '@components/Button';
import ContactForm from '@components/ContactForm';
import List from '@components/List';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import ResourceListing from '@components/views/ResourceListing';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const ProductIgnitePage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation(); const icon = getImage(data.icon.childImageSharp.gatsbyImageData);

	return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="standard"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content_1">
						<h2>
							The complete engineering tool to model physical systems and
							their sub-components
						</h2>
					</Trans>

					{icon && (
						<div className="product-lead-icon image-contain">
							<GatsbyImage
								image={icon}
								alt="IGNITE"
								className="icon-image"
							/>
						</div>
					)}
					<Trans i18nKey="content_2">
						<p>
							IGNITE is a physics-based system simulation package focused on complete vehicle system modelling and simulation.
							With a comprehensive suite of multi-domain system “building blocks”,
							users can quickly and accurately model complete conventional, hybrid-electric, full electric and novel vehicle architectures.
						</p>
						<h2>How it works</h2>
						<p>
							Faster than real-time execution speed and easy simulation control, the toolset provides quick analysis of vehicle performance,
							fuel economy and aftertreatment emissions from concept through to detailed design verification.
						</p>
						<h2>Bespoke user model development and expert systems consultancy</h2>
						<p>
							The Realis team is available to provide specific user systems modelling and functional support as a customised service
							or as part of a wider simulation project.
							Our team provides expert consultancy advice in developing systems models and component selection
							to maximise the toolset capabilities, to improve efficiency and reduce carbon emissions.
						</p>
					</Trans>
				</div>
				<Aside>
					<Aside.Widget
						title={t("_Applications")}
						className="widget__sibling__pages">
						<AssetList>
							{IGNITE_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
					<Link
						to={LINKS.ABOUT_CONTACT}
						className="button--more">
						{t("_GetInTouch")}
					</Link>
				</Aside>
			</PageSection>

			<PageSection title={t("_KeyFeatures")} layout="wide">
				<Trans i18nKey="features">
					<List type="grid">
						<li>
							Modern analysis environment for model building and simulation
						</li>
						<li>
							Application specific models covering conventional, hybrid/electric architectures, and common exhaust aftertreatment systems
						</li>
						<li>
							Direct coupling with WAVE/WAVE-RT
						</li>
						<li>
							Co-simulation with MATLAB Simulink®
						</li>
						<li>
							Functional Mock-up Interface (FMI) support
						</li>
						<li>
							Rapid, consistent model construction using proprietary or user-defined library elements
						</li>
						<li>
							Capable of distributed runs on multiple CPUs or High Performance Clusters
						</li>
						<li>
							Robust and faster than real-time simulation
						</li>
						<li>
							Direct integration of the fast and powerful Modelica compiler/solver
						</li>
						<li>
							Results visualization and analysis in an intelligent post-processing tool R-Post
						</li>
					</List>
				</Trans>
			</PageSection>

			<ResourceListing
				resources={data.resources}
				title={t("_OurInsights")}
				layout="fullwidth"
				actions={
					<div className="text--center">
						<Button to={LINKS.INSIGHTS} type="more">
							{t("_SeeAllInsights")}
						</Button>
					</div>
				}
			/>

		</Page>
	);
};

export default ProductIgnitePage;

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["products-ignite", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}
	banner: file(relativePath: { eq: "content/products/ignite/ignite-hero_1152x654.png" }) {
		...imageBreaker
	}
	icon: file(relativePath: { eq: "content/products/ignite/rs_ignite.png" }) {
		...imageStandard
	}
	resources: allMarkdownRemark(
		filter: {
			fileAbsolutePath: { regex: "/resources/" }
			fields: { language: { eq: $language } }
			frontmatter: { products: { in: "IGNITE" } }
		}
		sort: { fields: frontmatter___date, order: DESC }
		limit: 6
	) {
		nodes {
			fields {
				slug
			}
			frontmatter {
				title
				summary
				image {
					...imageStandard
				}
				type
			}
		}
	}
}
`;
